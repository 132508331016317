import React, { useEffect, useState } from "react";
import Styles from "./Login.module.css";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { publicIpv4 } from "public-ip";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = ({ setIsAuthenticated, setLoading, isAuthenticated }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState("");

  const handleFormData = (e, key) => {
    setFormData((prev) => {
      return {
        ...prev,
        [key]: e.target.value,
      };
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = {
        email: formData.email,
        password: formData.password,
        ip: await publicIpv4(),
      };
      const login = await axios.post("/api/auth", body);
      if (login?.data?.token) {
        localStorage.setItem("tokenLogin", login.data.token);
        setIsAuthenticated(true);
        setMessage(login?.data?.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className={Styles.App}>
      <div className={Styles.ContentWrapper}>
        <div className={Styles.FormTitle}>
          <h1>Login</h1>
        </div>
        <form onSubmit={onSubmit} className={Styles.FormWrapper}>
          <Input
            label
            textLabel={"Email"}
            placeholder={"example@email.com"}
            type={"email"}
            onChange={(e) => handleFormData(e, "email")}
          />
          <Input
            label
            textLabel={"Password"}
            type={"password"}
            onChange={(e) => handleFormData(e, "password")}
          />
          <Button type={"submit"} style={{ width: "100%" }}>
            Submit
          </Button>
          <p className={Styles.Message}>{message}</p>
        </form>
      </div>
    </div>
  );
};

export default Login;
