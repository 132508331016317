import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { CircularProgress } from "@mui/material";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Login from "./Pages/Login/Login";

const AppRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem("tokenLogin");
      if (token) {
        const decode = jwtDecode(token);
        if (decode.exp * 1000 < Date.now()) {
          setIsAuthenticated(false);
          localStorage.removeItem("tokenLogin");
          setLoading(false);
        } else {
          setIsAuthenticated(true);
          setLoading(false);
        }
      } else {
        setIsAuthenticated(false);
        setLoading(false);
      }
    };
    checkAuth();
  }, []);

  const RequireAuth = ({ children, redirectTo }) => {
    if (loading) {
      return <CircularProgress />;
    } else {
      return isAuthenticated ? children : <Navigate to={redirectTo} />;
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <Login
              setIsAuthenticated={setIsAuthenticated}
              setLoading={setLoading}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="/"
          element={
            <RequireAuth redirectTo={"/login"}>
              <Dashboard setIsAuthenticated={setIsAuthenticated} />
            </RequireAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
