import React, { useEffect, useState } from "react";
import Styles from "./Dashboard.module.css";
import CustomPagination from "../../Layout/Pagination/ui/Pagination";
import Table from "../../Layout/Table/Table";
// import statusJson from "../../../status.json";
// import statusJson from "../../";
import Button from "../../components/Button/Button.js";
import Input from "../../components/Input/Input.js";
import axios from "axios";
const Dashboard = ({ setIsAuthenticated }) => {
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState("");
  const [statusArray, setStatusArray] = useState([]);
  const [statusArrayCopy, setStatusArrayCopy] = useState([]);
  useEffect(() => {
    const getValues = async () => {
      try {
        const values = await axios.get("/api/status");
        setStatusArray(values.data.Values);
        setStatusArrayCopy(values.data.Values);
      } catch (error) {
        console.log(error);
      }
    };
    getValues();
  }, []);

  useEffect(() => {
    const changeFile = async () => {
      try {
        const postFile = await axios.post("/api/status", {
          arrayValues: statusArray,
        });
        console.log(postFile);
      } catch (error) {
        console.log(error);
      }
    };

    if (!arraysAreEqual(statusArray, statusArrayCopy)) {
      changeFile();
    }
  }, [statusArray, statusArrayCopy]);

  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
        return false;
      }
    }

    return true;
  }

  const handleValue = (e, name, key) => {
    setStatusArray((prev) =>
      prev.map((item) =>
        item.name === name ? { ...item, [key]: e.target.value } : item
      )
    );
  };

  const columns = [
    {
      accessorKey: "form_name",
      header: () => <div className={Styles.FirstColumn}>Name</div>,
      cell: ({ row }) => (
        <div className={Styles.FirstColumn}>{row.original.name}</div>
      ),
    },
    {
      accessorKey: "download",
      header: () => "Download",
      cell: ({ row }) => {
        return (
          <div className={Styles.Status}>
            <select
              value={row.original.download}
              className={
                row.original.download === "Done"
                  ? Styles.Done
                  : row.original.download === "In Progress"
                  ? Styles.Progress
                  : row.original.download === "N/A"
                  ? Styles.NA
                  : ""
              }
              onChange={(e) => handleValue(e, row.original.name, "download")}
            >
              <option value={"N/A"}>N/A</option>
              <option value={"In Progress"}>In Progress</option>
              <option value={"Done"}>Done</option>
            </select>
          </div>
        );
      },
    },
    {
      accessorKey: "parsed",
      header: () => "Parsed",
      cell: ({ row }) => (
        <div className={Styles.Status}>
          <select
            className={
              row.original.parsed === "Done"
                ? Styles.Done
                : row.original.parsed === "In Progress"
                ? Styles.Progress
                : row.original.parsed === "N/A"
                ? Styles.NA
                : ""
            }
            value={row.original.parsed}
            onChange={(e) => handleValue(e, row.original.name, "parsed")}
          >
            <option value={"N/A"}>N/A</option>
            <option value={"In Progress"}>In Progress</option>
            <option value={"Done"}>Done</option>
          </select>
        </div>
      ),
    },
    {
      accessorKey: "transaction",
      header: () => "Transaction",
      cell: ({ row }) => (
        <div className={Styles.Status}>
          <select
            className={
              row.original.transaction === "Done"
                ? Styles.Done
                : row.original.transaction === "In Progress"
                ? Styles.Progress
                : row.original.transaction === "N/A"
                ? Styles.NA
                : ""
            }
            value={row.original.transaction}
            onChange={(e) => handleValue(e, row.original.name, "transaction")}
          >
            <option value={"N/A"}>N/A</option>
            <option value={"In Progress"}>In Progress</option>
            <option value={"Done"}>Done</option>
          </select>
        </div>
      ),
    },
  ];

  const logout = () => {
    localStorage.removeItem("tokenLogin");
    setIsAuthenticated(false);
  };

  const filteredData = statusArray.filter((i) =>
    i.name.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <div className={Styles.App}>
      <div className={Styles.ContentWrapper}>
        <div className={Styles.TitleWrapper}>
          <h1>Forms</h1>
          <Button onClick={logout}>Logout</Button>
        </div>
        <div>
          <Input
            placeholder={"Name"}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className={Styles.TableWrapper}>
          <Table
            columns={columns}
            data={
              page === 0
                ? filteredData.slice(page * rowsPerPage, rowsPerPage)
                : filteredData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
            }
          />
          <CustomPagination
            count={filteredData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(newPage) => setPage(newPage)}
            onRowsPerPageChange={(newRowsPerPage) => {
              setRowsPerPage(newRowsPerPage);
              setPage(0);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
